<template>
  <div class="-dojo">
    <div class="c_main -pad-btm">
			<header class="c_main_hd">
				<div class="inner">
					<nav class="c_pnkz">
						<ul class="c_pnkz_list">
							<li><a class="c_pointer" @click="$router.push({ name: 'Home'})">ホーム</a></li>
							<li>サイバー道場</li>
						</ul>
					</nav>
					<h1 class="c_main_ttl">サイバー道場</h1>
				</div>
			</header>
			<div class="c_main_body">
				<div class="inner">
          <section-contents-list :categoryId="categoryId"></section-contents-list>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import SectionContentsList from "@/components/SectionContentsList";

export default {
  components: {
    SectionContentsList
  },
  computed: {
    categoryId() {
      return this.$store.getters.getCategoryIdByName("サイバー道場");
    }
  },
};
</script>